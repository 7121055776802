import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
    	<h3>{"andersinde.com"}</h3>
    </div>
  );
}

export default App;
